<template>
  <b-sidebar
    @change="sidebarChanged($event)"
    id="sidebar-border"
    v-model="isShown"
    sidebar-class="sidebar"
    shadow
    right
    no-header
    backdrop
  >
    <b-modal
      ref="confirm"
      id="modal-no-backdrop"
      hide-backdrop
      hide-footer
      hide-header
      body-bg-variant="light"
      content-class="shadow"
      title=""
    >
      <div>
        <p class="heading">Please confirm this action</p>
        <p class="my-2">
          This action cannot be reverse. Please confirm to continue
        </p>
      </div>
      <div class="d-flex" style="float: right">
        <button
          style="width: 60px; margin-right: 10px"
          class="btn btn-destructive"
          @click="removeCancel"
        >
          Cancel
        </button>
        <button
          style="width: 60px"
          class="btn btn-primary"
          :disabled="loading.delete"
          @click="remove"
        >
          <span
            v-show="loading.delete"
            class="spinner-border spinner-border-sm"
          ></span>
          <span v-show="!loading.delete">Okay</span>
        </button>
      </div>
    </b-modal>
    <div class="sidebar-body clearfix">
      <div class="header">
        <button @click="close" class="go-back">
          <b-icon icon="chevron-left"></b-icon>
        </button>

        <h6
          style="
            text-align: center;
            min-height: 22px;
            padding-top: 0px;
            width: 100%;
          "
        >
          {{ title }}
        </h6>
        <button :disabled="loading.delete" @click="removeComfirm">
          <span
            v-show="loading.delete"
            class="spinner-border spinner-border-sm"
          ></span>
          <b-icon
            v-show="!loading.delete"
            icon="archive-fill
"
          ></b-icon>
        </button>
      </div>
      <div class="form-content">
        <slot></slot>
      </div>
    </div>
  </b-sidebar>
</template>
<script>
import ikebana from "../services/ikebana";
export default {
  name: "Sidebar",
  props: {
    data: Object,
    type: String,
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isShown: false,
      loading: {
        delete: false,
      },
    };
  },
  methods: {
    sidebarChanged(event) {
      if (event === false) {
        this.$store.dispatch("RESET_EDIT");
        document.documentElement.style.overflow = "auto";
      } else {
        document.documentElement.style.overflow = "hidden";
      }
    },
    open() {
      this.isShown = true;
    },
    close() {
      this.isShown = false;
    },
    removeComfirm() {
      this.$refs.confirm.show();
    },
    removeCancel() {
      this.$refs.confirm.hide();
    },
    remove() {
      if (this.$store.state.edit.item) {
        this.loading.delete = true;
        ikebana.deleteItem(this.$store.state.edit.item.id).then(() => {
          this.loading.delete = false;
          this.isShown = false;
          this.$refs.confirm.hide();

          this.$store.dispatch("GET_ITEMS");
        });
      }

      if (this.$store.state.edit.client) {
        this.loading.delete = true;
        ikebana.deleteclient(this.$store.state.edit.client.id).then(() => {
          this.loading.delete = false;
          this.isShown = false;
          this.$refs.confirm.hide();
          this.$store.dispatch("GET_CLIENTS");
        });
      }

      if (this.$store.state.edit.user) {
        this.loading.delete = true;
        ikebana.deleteUser(this.$store.state.edit.user.id).then(() => {
          this.loading.delete = false;
          this.isShown = false;
          this.$refs.confirm.hide();
          this.$store.dispatch("GET_USERS");
        });
      }
    },
  },
};
</script>
<style lang="css" scoped>
.header {
  background: white;
  padding: 10px;
  display: flex;
  min-height: 50px !important;
  border-radius: 12px;
  margin-bottom: 10px;
}
.b-sidebar-body {
  background: #ccc !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sidebar-body {
  padding: 10px;
  position: relative;
  height: auto;
  min-height: 100% !important;
}
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}
.form-content {
  position: relative;
  background: rgb(255, 255, 255) !important;
  border-radius: 12px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
