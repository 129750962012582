<template>
  <div v-if="!loading">
    <div v-if="tracking" class="container my-2">
      <div class="row">
        <div class="col-md order-content">
          <div class="logo"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md order-content">
          <div class="d-flex justify-content-between">
            <div>
              <h6>Order Tracking - {{ tracking.trackingNumber }}</h6>
            </div>
            <div class="action-buttons">
              <button @click="downloadDn" class="btn">
                <b-icon icon="download"></b-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md order-content activities">
          <p class="heading text-center">Tracking History:</p>
          <div class="tracking-info">
            <div
              v-for="(activity, index) in tracking.activities"
              :class="index % 2 === 0 ? 'bg' : ''"
              :key="index"
              class="tracking-item"
            >
              <p>
                {{ activity.name }}
              </p>
              <div
                style="padding: 0; margin: 0"
                class="d-flex justify-content-between"
              >
                <span style="text-align: start" class="auditable">
                  {{ activity.dateTime | formatDate }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md order-content package-facts">
          <div class="facts">
            <p class="heading" style="text-align: start">Package Facts:</p>
            <div class="row">
              <div v-if="tracking.facts" class="col-md">
                <p style="text-align: start">
                  <span style="font-weight: bold">Number of Box:</span>
                  {{ tracking.facts.numberOfBox }} <br />
                  <span style="font-weight: bold">Weight:</span>
                  {{ tracking.facts.totalWeight }} <br />
                  <span style="font-weight: bold">Received By:</span>
                  {{ tracking.facts.signedBy }} <br />
                  <span style="font-weight: bold">Shipping Mode :</span>
                  {{ tracking.facts.transportationMode }} <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md order-content">
          <div class="notes">
            <p class="heading" style="text-align: start">Notes:</p>
            <div
              v-for="(item, index) in tracking.notes"
              :key="item.id"
              class="tracking-item"
              :class="index % 2 === 0 ? 'bg' : ''"
            >
              <p>
                {{ item.note }}
              </p>
              <div
                style="padding: 0; margin: 0"
                class="d-flex justify-content-between"
              >
                <span style="text-align: start" class="auditable">
                  {{ item.createdAt | formatDate }}
                </span>
                <span class="auditable"> {{ item.user }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md order-content">
          <h6>Order Attachments</h6>
          <div class="attachments">
            <img
              class="attachment rounded"
              v-for="img in tracking.attachments"
              :key="img.path"
              :src="img.path"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <b-spinner v-else class="spinner"></b-spinner>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      tracking: null,
      error: null,
      loading: true,
    };
  },
  methods: {
    downloadDn() {
      if (this.tracking.dnPath) {
        window.open(this.tracking.dnPath);
      }
    },
  },
  mounted: function () {
    this.loading = true;
    var id = this.$route.params.id;
    axios.get("/api/order/tracking/" + id).then(
      (response) => {
        this.loading = false;
        var user = this.$store.getters.getUser;
        this.tracking = response.data;
        if (user === null) {
          return;
        }

        this.$router.push("/order/details/" + this.tracking.id);
      },
      (error) => {
        this.loading = false;
        this.error = error.response.data.Message;
      }
    );
  },
};
</script>
<style lang="css" scoped>
.attachments {
  overflow-x: auto !important;
}

.attachment {
  height: 25vh;
  background-size: contain;
  border-radius: 12px !important;
}
.spinner {
  color: #ad1f23;
  position: absolute;
  top: 50%;
  width: 3rem;
  height: 3rem;
}
.logo {
  background: url("/logo.png") no-repeat;
  background-size: contain;
  cursor: pointer;
  min-height: 100px;
  min-width: 100px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.ordered-item {
  padding: 5px;
  border-radius: 15px;
  background: #e9e9e9;
  margin-bottom: 5px;
}
.tracking-item p {
  padding: 0px !important;
}
.bg {
  background: #f2f2f2;
}

.auditable {
  color: #9e9e9e;
  font-size: 12px;
}
.tracking-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  text-align: start;
  padding-bottom: 1px;
  border-radius: 15px;
  margin-bottom: 5px;
}
.order-content {
  padding: 10px;
  border-radius: 15px;
  background: white !important;
  vertical-align: middle !important;
  margin-bottom: 10px;
  margin-right: 10px;
}
</style>
