<template>
  <div v-if="!loading">
    <div v-if="order" class="order-details">
      <transition name="fade">
        <div @click="closePreview" v-show="selectedImage" class="image">
          <img :src="selectedImage" alt="" />
        </div>
      </transition>
      <b-modal
        ref="cancelOrder"
        id="modal-no-backdrop"
        hide-backdrop
        hide-footer
        hide-header
        body-bg-variant="light"
        content-class="shadow"
        title=""
      >
        <div>
          <p class="heading">Please confirm this action</p>
          <p class="my-2">
            This action cannot be reverse. Please confirm to continue
          </p>
          <textarea
            cols="30"
            v-on="reason"
            placeholder="Enter a reason for the cancellation"
            rows="5"
          ></textarea>
        </div>
        <div class="d-flex" style="float: right">
          <button
            style="width: 60px; margin-right: 10px"
            class="btn btn-destructive"
            @click="removeCancel"
          >
            Cancel
          </button>
          <button
            style="width: 60px"
            class="btn btn-primary"
            :disabled="loading_cancel"
            @click="cancelOrder"
          >
            <span
              v-show="loading_cancel"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-show="!loading_cancel">Okay</span>
          </button>
        </div>
      </b-modal>
      <b-modal
        ref="shareTrackingConfirm"
        id="modal-no-backdrop"
        hide-backdrop
        hide-footer
        hide-header
        body-bg-variant="light"
        content-class="shadow"
        title=""
      >
        <div>
          <p class="heading">Please confirm this action</p>
          <p class="my-2">
            This action cannot be reverse. Please confirm to continue <br />
            An Sms will be sent to client with tracking number.
          </p>
        </div>
        <div class="d-flex" style="float: right">
          <button
            style="width: 60px; margin-right: 10px"
            class="btn btn-destructive"
            @click="$refs.shareTrackingConfirm.hide()"
          >
            Cancel
          </button>
          <button
            style="width: 60px"
            class="btn btn-primary"
            :disabled="loading_share"
            @click="shareTracking"
          >
            <span
              v-show="loading_share"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-show="!loading_share">Okay</span>
          </button>
        </div>
      </b-modal>
      <Sidebar title="Edit Order" ref="editsidebar">
        <Edit @hide="editsidebarClose" :order="order" />
      </Sidebar>
      <div
        class="order-content row d-flex justify-content-between"
        style="margin-left: 0px !important; margin-right: 0px !important"
      >
        <div class="col-md">
          <div class="d-flex">
            <img
              style="background: #fff; margin-right: 10px"
              width="80"
              height="80"
              :src="order.client.logo"
            />
            <p style="text-align: start">
              Client: <strong> {{ order.client.name }}</strong>
              <br />
              <span v-if="order.correspondents">
                Correspondents:
                <strong
                  v-for="(correspondent, index) in order.correspondents"
                  :key="correspondent.id"
                  >{{
                    index === 0 ? correspondent.name : ", " + correspondent.name
                  }}</strong
                >
              </span>
              <br />
              <span>PO Number:</span>
              <span v-if="po_edit">
                <input
                  style="
                    height: 25px;
                    border-top-right-radius: 0px !important;
                    border-bottom-right-radius: 0px !important;
                  "
                  type="text"
                  placeholder="Enter PO Number"
                  v-model="order.po"
                />
                <button
                  style="
                    height: 25px;
                    border-top-left-radius: 0px !important;
                    border-bottom-left-radius: 0px !important;
                  "
                  @click="updatePo"
                >
                  <b-icon scale="2" icon="check"></b-icon>
                </button>
              </span>
              <span v-else
                ><strong>{{ order.po }}</strong>
                <b-icon
                  v-show="order.team.displayName === 'Order Billing'"
                  @click="po_edit = true"
                  icon="pencil"
                ></b-icon>
              </span>
              <br />
              <span>Invoice #:</span>
              <span v-if="in_edit">
                <input
                  style="
                    height: 25px;
                    border-top-right-radius: 0px !important;
                    border-bottom-right-radius: 0px !important;
                  "
                  type="text"
                  placeholder="Enter Invoice Number"
                  v-model="order.invoiceId"
                />
                <button
                  style="
                    height: 25px;
                    border-top-left-radius: 0px !important;
                    border-bottom-left-radius: 0px !important;
                  "
                  @click="updatePo"
                >
                  <b-icon scale="2" icon="check"></b-icon>
                </button>
              </span>
              <span v-else
                ><strong>{{ order.invoiceId }}</strong>
                <b-icon
                  v-show="order.team.displayName === 'Order Billing'"
                  @click="in_edit = true"
                  icon="pencil"
                ></b-icon>
              </span>
              <br />
              Delivery Note: <strong>{{ order.dnId }}</strong>
              <br />
              <span style="font-size: 14px; color: #ad1f23; text-align: end">
                <span class="tag" style="background: #ad1f23">
                  Delivery Date:
                  <strong> {{ order.dueDate | formatDate }}</strong>
                </span>
              </span>
              <br />
              <span style="font-size: 14px; color: #ad1f23; text-align: end">
                <span class="tag" style="background: #ffa500; color: black">
                  Transport Mode:<strong> {{ order.transportMethod }}</strong>
                </span>
              </span>
            </p>
          </div>
        </div>
        <div style="padding: 0px !important" class="col-md-5">
          <div class="d-flex justify-content-end">
            <button
              @click="cancelConfirm"
              style="
                width: 50px;
                height: 50px;
                padding: 10px;
                background: none;
                border: 1px solid red;
                color: red;
              "
            >
              <span>
                <b-icon icon="archive"></b-icon>
              </span>
            </button>
            <button
              @click="shareTrackingConfirm"
              style="
                margin-left: 10px;
                width: 50px;
                height: 50px;
                padding: 10px;
                background: none;
                border: 1px solid #e8e8e8;
              "
            >
              <span>
                <b-icon icon="share" />
              </span>
            </button>
            <button
              @click="editOrder"
              v-show="
                $store.getters.getUser.role === 'Admin' &&
                order.team.canTakeAction
              "
              style="
                margin-left: 10px;
                width: 50px;
                height: 50px;
                padding: 10px;
                background: none;
                border: 1px solid #e8e8e8;
              "
            >
              <span>
                <b-icon icon="pencil" />
              </span>
            </button>
            <button
              @click="download"
              v-show="order.dnPath"
              style="
                margin-left: 10px;
                width: 50px;
                height: 50px;
                padding: 10px;
                background: none;
                border: 1px solid #e8e8e8;
              "
            >
              <span>
                <b-icon icon="download" />
              </span>
            </button>
            <button
              @click="print"
              style="
                margin-left: 10px;
                width: 50px;
                height: 50px;
                padding: 10px;
                background: none;
                border: 1px solid #e8e8e8;
              "
            >
              <span>
                <b-icon icon="printer" />
              </span>
            </button>
            <button
              v-show="order.team.canTakeAction"
              v-if="isAuthorized"
              style="margin-left: 10px"
              @click="start === null ? startAction() : endAction()"
              :class="this.start === null ? '' : 'end'"
              class="order-action-btn"
            >
              <span
                v-show="loading_next"
                class="spinner-border spinner-border-sm"
              ></span>
              <span v-show="!loading_next"> {{ getDisplayText }} </span>
            </button>
            <button
              v-show="
                !order.team.canTakeAction &&
                order.team.displayName === 'Order Cancelled'
              "
              style="width: 150px !important"
              @click.prevent
              :class="this.start === null ? '' : 'end'"
              class="btn destructive"
            >
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <span v-show="!loading_next"> {{ order.team.displayName }} </span>
            </button>
          </div>
        </div>
      </div>
      <b-modal
        ref="iteminfo"
        cancel-disabled
        hide-header
        hide-footer
        ok-disabled
        id="modal-tall"
        title=""
      >
        <button @click="closeItemDetails">
          <b-icon icon="x"></b-icon>
        </button>
        <div v-if="selectedItem" class="d-block text-center">
          <h3>{{ selectedItem.product.name }}</h3>
          <img class="info-image" :src="selectedItem.product.image" alt="" />
          <h5>Size: {{ selectedItem.productSize }}</h5>
          <p class="text-center text-wrap">
            {{ selectedItem.product.description }}
          </p>
        </div>
      </b-modal>
      <div v-if="order.customerNote" class="order-content customer-note">
        <p class="heading" style="text-align: start">Customer Note:</p>
        <h6 class="text-start" style="color: black !important">
          {{ order.customerNote }}
        </h6>
      </div>
      <div class="order-content order-tags">
        <p class="heading" style="text-align: start">Tags:</p>
        <div style="margin: 0px; padding: 0px" class="row order-tags">
          <div
            class="d-flex justify-content-between tag"
            v-for="(tag, index) in order_tags"
            :key="index"
          >
            <span
              >{{ tag }}
              <button @click="removeTag(tag)">
                <b-icon style="margin-left: 10px" icon="x"></b-icon>
              </button>
            </span>
          </div>
          <div class="d-flex justify-content-between tag-input">
            <transition name="slide-fade" mode="out-in">
              <input
                ref="tagger"
                autofocus
                @keyup.esc="endTagging"
                @keyup.enter="saveTags"
                @submit="saveTags"
                v-if="taggable"
                v-model="new_tag"
                type="text"
              />
              <button
                style="
                  width: 20px !important;
                  background: #008cf6;
                  border-radius: 5px !important;
                "
                @click="startTagging"
                v-else
              >
                <b-icon icon="plus"></b-icon>
              </button>
            </transition>
          </div>
        </div>
      </div>
      <div class="order-content order-items">
        <p class="heading" style="text-align: start">Items</p>
        <div class="order-item-list">
          <button
            @click="selectItem(item)"
            v-for="item in order.items"
            :key="item.id"
            class="d-flex ordered-item justify-content-between"
          >
            <div class="d-flex">
              <img
                class="rounded-circle"
                width="40"
                height="40"
                :src="item.product.image"
                alt=""
              />
              <p style="padding-left: 10px">
                {{ item.product.name }} - {{ item.productSize }}
              </p>
            </div>
            <p style="text-align: end; padding-right: 10px">
              {{ item.qty }} {{ item.product.unit }}
            </p>
          </button>
        </div>
      </div>
      <div class="order-content order-references">
        <p class="heading" style="text-align: start">Reference Images:</p>
        <div class="attachments">
          <div
            @click="previewImage(img)"
            :key="img.path"
            v-for="(img, index) in order.attachments"
          >
            <Attachment
              :data="img"
              @remove="removeImage"
              :index="index"
              :orderId="order.id"
            />
          </div>
        </div>
        <div class="my-2 d-flex justify-content-between">
          <div></div>
          <input
            type="file"
            @change="onFileChange"
            multiple
            accept="image/png, image/gif, image/jpeg"
            class="file-input"
          />
        </div>
      </div>
      <div class="order-content notes">
        <p class="heading" style="text-align: start">Client notes:</p>
        <div class="tracking-info">
          <div
            v-for="(item, index) in order.additionalNote.filter(
              (x) => x.isPublic === true
            )"
            :key="item.id"
            class="tracking-item"
            :class="index % 2 === 0 ? 'bg' : ''"
          >
            <p v-html="item.note"></p>

            <div
              style="padding: 0; margin: 0"
              class="d-flex justify-content-between"
            >
              <span style="text-align: start" class="auditable">
                {{ item.createdAt | formatDate }}
              </span>
              <span class="auditable"> {{ item.user }}</span>
            </div>
          </div>
        </div>
        <textarea
          name="remarks"
          id=""
          cols="30"
          v-model="public_note"
          required
          style="
            width: 100%;
            resize: none;
            border: 1px solid #e9e9e9;
            border-radius: 12px;
          "
          rows="3"
        ></textarea>
        <div class="d-flex justify-content-between">
          <div></div>
          <button @click="addNote(true)" class="btn primary add-note">
            <span
              v-show="loading_note"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-show="!loading_note">Add Client Note</span>
          </button>
        </div>
      </div>
      <div class="order-content notes">
        <p class="heading" style="text-align: start">Additional notes:</p>
        <div class="tracking-info">
          <div
            v-for="(item, index) in order.additionalNote.filter(
              (x) => x.isPublic === false
            )"
            :key="item.id"
            class="tracking-item"
            :class="index % 2 === 0 ? 'bg' : ''"
          >
            <p v-html="item.note"></p>
            <div
              style="padding: 0; margin: 0"
              class="text-start d-flex justify-content-between"
            >
              <span style="text-align: start" class="auditable">
                {{ item.createdAt | formatDate }}
              </span>
              <span class="auditable"> {{ item.user }}</span>
            </div>
          </div>
        </div>
        <textarea
          name="remarks"
          id=""
          cols="30"
          v-model="new_note"
          required
          style="
            width: 100%;
            resize: none;
            border: 1px solid #e9e9e9;
            border-radius: 12px;
          "
          rows="3"
        ></textarea>
        <div class="d-flex justify-content-between">
          <div></div>
          <button @click="addNote(false)" class="btn primary add-note">
            <span
              v-show="loading_note"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-show="!loading_note">Add Note</span>
          </button>
        </div>
      </div>
      <div class="order-content activities">
        <div class="d-flex justify-content-between">
          <p class="heading" style="text-align: start">Tracking History:</p>

          <button
            @click="shareTrackingConfirm"
            style="
              margin-left: 10px;
              padding: 5px;
              background: none;
              margin-bottom: 8px;
              color: gray;
            "
          >
            <span>
              <b-icon icon="share" />
              Share
            </span>
          </button>
        </div>
        <div class="tracking-info">
          <div
            v-for="(activity, index) in order.activities"
            :class="index % 2 === 0 ? 'bg' : ''"
            :key="activity.id"
            class="tracking-item"
          >
            <p>
              {{ activity.name }}
            </p>
            <div
              style="padding: 0; margin: 0"
              class="d-flex justify-content-between"
            >
              <span style="text-align: start" class="auditable">
                {{ activity.end | formatDate }}
              </span>
              <span class="auditable"> {{ activity.handler }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="order.orderFact" class="order-content package-facts">
        <p class="heading" style="text-align: start">Package Facts:</p>
        <div class="row p-2">
          <div
            class="col-md"
            style="
              border: 2px dashed #ccc;
              border-radius: 12px;
              padding-top: 10px;
              padding-bottom: 10px;
            "
          >
            <p style="text-align: start">
              <span style="font-weight: bold">Number of Box:</span>
              {{ order.orderFact.numberOfBox }} <br />
              <span style="font-weight: bold">Weight:</span>
              {{ order.orderFact.weight }} <br />
              <span style="font-weight: bold">Received By:</span>
              {{ order.orderFact.signee }} <br />
              <span style="font-weight: bold">Contact:</span>
              {{ order.orderFact.contact }} <br />
              <span style="font-weight: bold">Transportation Mode:</span>
              {{ order.orderFact.transportationMode }} <br />
              <span style="font-weight: bold">Date Delivered:</span>
              {{ order.orderFact.handOverDateTime | formatDate }}
            </p>
          </div>
          <div
            class="col-md signature-view"
            style="border: 2px dashed #ccc; border-radius: 12px"
          >
            <p class="">Signature of {{ order.orderFact.signee }}</p>
            <img :src="order.orderFact.pathToSignature" class="sign" />
          </div>
        </div>
      </div>
      <Sidebar :title="order.team.displayName + ' Information'" ref="sidebar">
        <Delivery
          v-if="order.team.displayName === 'Order Delivery'"
          @hide="hide"
          :order="order"
          @endAction="markAsDelivered"
        ></Delivery>
        <Packing
          @hide="hide"
          :order="order"
          @endAction="finishedPacking"
          v-if="order.team.displayName === 'Order Packing'"
        />
      </Sidebar>
    </div>
  </div>

  <b-spinner v-else class="spinner"></b-spinner>
</template>
<script>
import Edit from "./Edit.vue";
import ikebana from "../../services/ikebana";
import Sidebar from "../../components/Sidebar.vue";
import Delivery from "./Delivery.vue";
import Packing from "../../views/Order/Packing.vue";
import Attachment from "../../components/Attachments.vue";
import { mapActions } from "vuex";

export default {
  components: {
    Attachment,
    Sidebar,
    Edit,
    Delivery,
    Packing,
  },
  data() {
    return {
      selectedItem: null,
      public_note: "",
      order_tags: [],
      selectedImage: null,
      po_edit: false,
      in_edit: false,
      new_tag: null,
      taggable: false,
      order: null,
      show: false,
      new_note: "",
      loading: false,
      loading_next: false,
      loading_note: false,
      loading_tag: false,
      loading_share: false,
      loading_cancel: false,
      reason: "",
      status: null,
      start: null,
      end: null,
      tags: [],
    };
  },
  methods: {
    ...mapActions,
    removeImage(index) {
      this.order.attachments.splice(index, 1);
    },
    infoChanged(event) {
      if (event === false) {
        this.selectedItem = null;
      }
    },
    selectItem(item) {
      this.selectedItem = item;
      this.$refs.iteminfo.show();
    },
    closeItemDetails() {
      this.$refs.iteminfo.hide();
    },
    updatePo() {
      ikebana.updateOrder(this.order.id, this.order).then(() => {
        this.$notify({
          group: "success",
          title: "Order update",
          text: "Purchase order updated",
        });
      });
    },
    download() {
      ikebana.downloadDn(this.order.dnPath).then(
        (response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.click();
          URL.revokeObjectURL(link.href);
        },
        () => {
          alert("Error while downloading the file. This looks like a bug");
        }
      );
    },
    previewImage(img) {
      this.selectedImage = img.path;
      document.documentElement.style.overflow = "hidden";
    },
    closePreview() {
      this.selectedImage = null;
      document.documentElement.style.overflow = "auto";
    },
    startTagging() {
      this.taggable = true;
    },
    endTagging() {
      this.taggable = false;
    },
    startAction() {
      this.start = Date.now();
      this.new_tag = "In Progress";
      this.saveTags();
    },
    endAction() {
      if (this.order.team.displayName === "Order Delivery") {
        this.$refs.sidebar.open();
        return;
      }

      if (this.order.team.displayName === "Order Packing") {
        this.$refs.sidebar.open();
        return;
      }
      this.end = Date.now();
      this.endConfirmed();
    },
    filter(type, value) {
      this.$store.commit("FILTER", {
        type: type,
        value: value.displayName,
      });
      // if (this.$route.name !== "Order List") {
      //   this.$router.go(-1);
      // }
    },
    finishedPacking(response) {
      this.$refs.sidebar.close();
      this.order.team = response.data.team;
      this.order.activities = response.data.activities;
      this.filter("type", response.data.team);
      this.$store.dispatch("UPDATE_ORDER", response.data);
      this.start = null;
    },
    markAsDelivered(response) {
      this.$refs.sidebar.close();
      this.order.dnPath = response.data.dnPath;
      this.order.activities = response.data.activities;
      this.order.team = response.data.team;
      this.filter("type", response.data.team);
      this.$store.dispatch("UPDATE_ORDER", response.data);
      this.start = null;
    },
    endConfirmed() {
      this.loading_next = true;
      ikebana.next(this.order.id, this.order.team.displayName).then(
        (response) => {
          this.loading_next = false;
          this.order.team = response.data.team;
          if (response.data.tags) {
            this.order_tags = response.data.tags.split(",");
          } else {
            this.order_tags = [];
          }
          this.order.activities = response.data.activities;
          this.filter("type", response.data.team);
          this.$store.dispatch("UPDATE_ORDER", response.data);
          this.start = null;
          this.end = null;
        },
        (error) => {
          this.loading_next = false;
          this.$notify({
            duration: 5000,
            group: "error",
            title: "Important message",
            text: error.response.data.Message,
          });
        }
      );
    },

    viewInvoice(order) {
      this.$router.push({
        name: "Orders",
        params: { client: order.client, filter: "All Orders" },
      });
    },
    hide() {
      this.$refs.sidebar.close();
    },
    editsidebarClose() {
      this.$refs.editsidebar.close();
    },
    editOrder() {
      this.$refs.editsidebar.open();
    },
    shareTrackingConfirm() {
      this.$refs.shareTrackingConfirm.show();
    },
    print() {
      var confirm = window.confirm(
        "Are you sure you want to print label for this order?"
      );
      if (!confirm) {
        return;
      }
      ikebana.printLabel(this.order.id).then(
        () => {},
        (error) => {
          this.$notify({
            duration: 5000,
            group: "error",
            title: "Important message",
            text: error.response.data.Message,
          });
        }
      );
    },
    shareTracking() {
      this.loading_share = true;
      ikebana.shareTracking(this.order.id).then(
        (response) => {
          this.loading_share = false;
          this.$refs.shareTrackingConfirm.hide();
          this.$notify({
            duration: 5000,
            group: "success",
            title: "Important message",
            text: response.data,
          });
        },
        (error) => {
          this.loading_share = false;
          this.$refs.shareTrackingConfirm.hide();
          this.$notify({
            duration: 5000,
            group: "error",
            title: "Important message",
            text: error.response.data.Message,
          });
        }
      );
    },
    cancelOrder() {
      this.loading_cancel = true;
      ikebana.cancelOrder(this.order.id, this.reason).then(
        (response) => {
          this.loading_cancel = false;
          this.order.team = response.data.team;
          this.$notify({
            duration: 5000,
            group: "success",
            title: "Important message",
            text: "The order has been cancelled\n" + this.reason,
          });
          this.$store.dispatch("UPDATE_ORDER", response.data);
          this.filter("type", response.data.team);
          this.$refs.cancelOrder.hide();
        },
        (error) => {
          this.loading_cancel = false;
          this.$notify({
            duration: 5000,
            group: "error",
            title: "Important message",
            text: error.response.data.Message,
          });
          this.$refs.cancelOrder.hide();
        }
      );
      this.reason = "";
    },
    cancelConfirm() {
      this.$refs.cancelOrder.show();
    },
    removeCancel() {
      this.$refs.cancelOrder.hide();
    },
    removeTag(tag) {
      this.loading_tag = true;
      ikebana.removeTag(this.order.id, tag).then(
        (response) => {
          this.loading_tag = false;
          if (response.data) {
            this.order_tags = response.data.split(",");
            this.order.tags = response.data;
            this.$store.dispatch("UPDATE_ORDER", this.order);
          } else {
            this.order_tags = [];
            this.order.tags = null;
            this.$store.dispatch("UPDATE_ORDER", this.order);
          }
          this.new_tag = null;
          this.taggable = false;
        },
        (error) => {
          this.loading_tag = false;
          this.$notify({
            duration: 5000,
            group: "error",
            title: "Important message",
            text: error.response.data.Message,
          });
          this.new_tag = null;
        }
      );
    },
    saveTags() {
      if (this.new_tag !== null) {
        this.loading_tag = true;
        ikebana.addTag(this.order.id, this.new_tag).then(
          (response) => {
            this.loading_tag = false;
            if (response.data) {
              this.order_tags = response.data.split(",");
              this.order.tags = response.data;
              this.$store.dispatch("UPDATE_ORDER", this.order);
            } else {
              this.order_tags = [];
              this.order.tags = null;
              this.$store.dispatch("UPDATE_ORDER", this.order);
            }
            this.new_tag = null;
            this.taggable = false;
          },
          (error) => {
            this.loading_tag = false;
            this.$notify({
              duration: 5000,
              group: "error",
              title: "Important message",
              text: error.response.data.Message,
            });
            this.new_tag = null;
          }
        );
      }
    },
    addNote(isPublic) {
      var note = this.new_note;
      if (isPublic) {
        var confirm = window.confirm(
          "This note will be seen by the correspondent and the client. Please confirm"
        );
        if (!confirm) {
          return;
        }

        note = this.public_note;
      }

      if (this.note !== "") {
        this.loading_note = true;
        ikebana.addNote(this.order.id, note, isPublic).then(
          (response) => {
            this.loading_note = false;
            this.order.additionalNote.push(response.data);
            this.note = "";
            this.new_note = "";
            this.public_note = "";
          },
          (error) => {
            this.loading_note = false;
            this.$notify({
              duration: 5000,
              group: "error",
              title: "Important message",
              text: error.response.data.Message,
            });
          }
        );

        this.new_note = "";
      }
    },
    onFileChange(e) {
      let formData = new FormData();
      e.target.files.forEach((file) => {
        formData.append("images", file);
      });
      ikebana.addImages(this.order.id, formData).then(
        (response) => {
          this.$notify({
            duration: 5000,
            group: "success",
            title: "Important message",
            text: "Images added successfully",
          });
          response.data.forEach((element) => {
            this.order.attachments.push(element);
          });
        },
        (error) => {
          this.$notify({
            duration: 5000,
            group: "error",
            title: "Important message",
            text: error.response.data.Message,
          });
        }
      );
      // if (e.target.files[0] !== undefined) {
      //   this.imageFile = e.target.files[0];
      //   this.fileName = this.imageFile.name;
      //   this.url = URL.createObjectURL(this.imageFile);
      // }
    },
  },
  computed: {
    isAuthorized() {
      var me = this.$store.getters.getUser;
      var check = me.assignedTeams.findIndex(
        (t) => t.displayName === this.order.team.displayName
      );
      return check == -1 ? true : false;
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    getDisplayText() {
      if (this.order.team === undefined) {
        return;
      }

      if (this.order.team.index === 1) {
        return "Send to Processing";
      } else {
        var status = this.order.team.displayName.split(" ")[1];
        return "Start " + status;
      }
    },
  },
  mounted: function () {
    if (this.order === null) {
      this.loading = true;
      ikebana.getOrder(this.$route.params.id).then(
        (response) => {
          this.order = response.data;
          this.loading = false;
          if (response.data.tags !== null) {
            this.order_tags = this.order.tags.split(",");
          }
        },
        () => {
          // var err =
          //   (error.response &&
          //     error.response.data &&
          //     error.response.data.message) ||
          //   error.message ||
          //   error.toString();
          this.loading = false;
        }
      );
    }
  },
  created: function () {
    // this.loading = true;
    // ikebana.getOrder(this.$route.params.id).then(
    //   (response) => {
    //     this.order = response.data;
    //     this.loading = false;
    //     // if (response.data.tags !== null) {
    //     //   var tags = response.data.tags.split(",");
    //     //   tags.forEach((tag) => {
    //     //     this.order_tags.push(tag);
    //     //   });
    //     // }
    //   },
    //   () => {
    //     // var err =
    //     //   (error.response &&
    //     //     error.response.data &&
    //     //     error.response.data.message) ||
    //     //   error.message ||
    //     //   error.toString();
    //     this.loading = false;
    //   }
    // );
  },
};
</script>
<style lang="css" scoped>
.btn .close {
  width: 25px;
  height: 25px;
}
.info-image {
  max-height: 50vh;
  min-height: 30vh;
  background-size: contain;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@media screen and (min-width: 766px) {
  .signature-view {
    margin-left: 10px !important;
  }
}

@media screen and (max-width: 766px) {
  .signature-view {
    margin-top: 5px !important;
  }
}

.image {
  z-index: 9999999;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.image img {
  position: absolute;
  opacity: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 90%;
  max-height: 90vh;
}
.sign {
  width: 100%;
}

.file-input {
  width: 100px;
  background: #008cf6 !important;
  color: white;
  background-size: cover;
}

.file-input::-webkit-file-upload-button {
  visibility: hidden;
  width: 100%;
}
.file-input::before {
  content: "Add Image";
  -webkit-user-select: none;
  cursor: pointer;
  font-size: 12pt;
  z-index: 9999;
}
.file-input:hover::before {
  border-color: black;
}
.add-note {
  width: auto !important;
}
.action-not-started td {
  border-bottom: none !important;
}
.action-started td {
  border-bottom: none !important;
  box-shadow: inset 0 0 5px #b6b6b6;
  -webkit-transition: border 500ms linear;
  -ms-transition: border 500ms linear;
  transition: border 500ms linear;
}

.order-action-btn {
  width: 100%;
  max-width: 220px;
  height: 50px;
  background: #cae9c4;
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}
.end {
  background: #1aa100;
  color: white;
}
a {
  text-decoration: none;
}
.first-row {
  margin-top: 100px;
}
p {
  text-align: justify;
  font-size: 14px !important;
}
tr {
  border: none !important;
  cursor: pointer;
  background: white;
}
a {
  font-size: 14px !important;
}
.attachments {
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  height: 250px;
}
.attachments > .attachment > .remove {
  background: rgba(44, 44, 44, 0.5);
  width: 40px !important;
  height: 40px !important;
  color: red;
  font-size: 16px;
  position: fixed;
}
.attachments > .attachment {
  height: 200px !important;
}
.attachments > .attachment > img {
  border-radius: 12px;
  margin-right: 10px;
  height: 85%;
}
.order-references {
  width: 89.6vw;
}

@media (max-width: 768px) {
  .cats-container p {
    font-size: 10px !important;
    text-align: start !important;
  }
}

.order-details {
  margin-top: 10px;
}
.auditable {
  color: #9e9e9e;
  font-size: 12px;
}
.ordered-item {
  padding: 5px;
  border-radius: 15px;
  background: #e9e9e9;
  margin-bottom: 5px;
  width: 100%;
}
.tracking-item p {
  padding: 0px !important;
}
.bg {
  background: #f2f2f2;
}

.tracking-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 1px;
  border-radius: 15px;
  margin-bottom: 5px;
}
.order-content {
  padding: 10px;
  border-radius: 15px;
  background: white !important;
  vertical-align: middle !important;
  margin-bottom: 10px;
}

p {
  text-align: start;
}
.qty-buttons button {
  background: #4e3232;
  width: 50px;
  height: 50px;
  margin-left: 5px;
}
</style>
