<template>
  <div>
    <div style="margin-top: 10px" class="row">
      <div class="col-md-4 advance-filter">
        <div class="d-flex">
          <button
            @click="$store.commit('REMOVE_FILTER_TAGS', tag)"
            v-for="(tag, index) in tags"
            :key="index"
            class="tag"
          >
            {{ tag }}
          </button>

          <div class="d-flex justify-content-between tag-input">
            <transition name="slide-fade" mode="out-in">
              <input
                @keyup.esc="endTagging"
                @keyup.enter="saveTags"
                @submit="saveTags"
                v-if="taggable"
                v-model="new_tag"
                type="text"
              />
              <button
                style="
                  width: 20px !important;
                  background: #008cf6;
                  border-radius: 5px !important;
                "
                @click="startTagging"
                v-else
              >
                <b-icon icon="plus"></b-icon>
              </button>
            </transition>
          </div>
        </div>
        <div v-if="filterByClient" class="d-flex">
          <button @click="removeClientFilter" class="tag client-tag">
            {{ filterByClient.name }}
          </button>
        </div>
      </div>
      <div style="padding-left: 0" class="search col-md">
        <div style="margin-right: 10px" class="row ms-auto">
          <input
            class="col-md"
            name="from"
            type="date"
            placeholder="From"
            v-model="from"
          />
          <input class="col-md" type="date" placeholder="To" v-model="to" />
          <input
            class="col-md"
            type="text"
            placeholder="Search"
            v-model="search"
          />
          <div class="client-filter col-md">
            <div class="d-flex">
              <multiselect
                track-by="name"
                v-model="filterByClient"
                :multiple="false"
                deselectLabel=""
                :close-on-select="true"
                :clear-on-select="true"
                :block-keys="['Tab']"
                :preselect-first="false"
                selectLabel=""
                :showNoResults="false"
                :taggable="false"
                openDirection="bottom"
                label="name"
                :searchable="true"
                placeholder="Add Client"
                :options="$store.state.clients"
              />
              <button @click="setListStyle">
                <b-icon
                  :icon="
                    $store.getters.getListStyle === 'extended'
                      ? 'grid'
                      : 'menu-down'
                  "
                ></b-icon>
              </button>
              <button @click="exportOrders">
                <b-icon icon="download"></b-icon>
              </button>
            </div>
          </div>

          <!-- <div>
            <button class="col-md">
              <b-icon icon="filter"></b-icon>
            </button>
          </div> -->
        </div>
      </div>
    </div>
    <Sidebar title="Add New Order" ref="sidebar">
      <New @hide="hide" :order="this.$store.state.new_order" />
    </Sidebar>
    <b-spinner v-show="$store.state.loading.orders" class="spinner"></b-spinner>
    <div v-if="!$store.state.loading.orders" class="order-list">
      <transition
        el="div"
        :key="$store.state.filter.type"
        name="slide-fade"
        mode="out-in"
      >
        <OrderList
          :key="$store.state.filter.type"
          :list="getOrders"
        ></OrderList>
      </transition>
    </div>
    <Add text="orders" @hide="hide" :action="addNewOrder" icon="plus" />
  </div>
</template>
<script>
import New from "./New.vue";
import OrderList from "../../components/OrderList.vue";
import Add from "../../components/Add.vue";
import Multiselect from "vue-multiselect";
import moment from "moment";

import Sidebar from "../../components/Sidebar.vue";
import ikebana from "../../services/ikebana";
export default {
  components: { OrderList, Sidebar, Add, New, Multiselect },
  data() {
    return {
      from: localStorage.getItem("from"),
      to: localStorage.getItem("to"),
      toggleFilter: false,
      orders: this.$store.getters.getOrders,
      tags: this.$store.getters.getTags,
      filterByClient: null,
      loading: false,
      filter: this.$store.state.filter.type,
      search: "",
      filteredOrders: null,
      taggable: false,
      new_tag: null,
    };
  },
  computed: {
    getCount() {
      return this.orders.length;
    },
    // getOrdersFilteredByTags(){
    //   return this.orders.filter((o) => {
    //     return o.tags.
    //   })
    // }
    filterClient() {
      if (this.filterByClient !== null) {
        return this.$store.getters.getOrders.filter((o) => {
          return o.client.name === this.filterByClient.name;
        });
      }

      return this.$store.getters.getOrders;
    },
    getOrders() {
      // return this.users.filter((user) => {
      //   return JSON.stringify(user).match(this.search.toLowerCase());
      // });
      // if (this.search === "") {
      //   return this.orders;
      // }
      var orders = this.filterClient;
      orders = orders.filter((o) => {
        return JSON.stringify(o).toLowerCase().match(this.search.toLowerCase());
      });
      // const computed = products.filter(product => product.categories.every(item => checked.includes(item) || !checked.length));
      if (this.tags.length > 0) {
        var filteredOrders = orders
          .filter((x) => x.tags !== null)
          .filter((order) => {
            var orderTags = order.tags.split(",");
            return this.tags.every((filterTags) => {
              return orderTags.includes(filterTags);
            });
          });

        return filteredOrders;
      }

      return orders;
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    from: function (a) {
      // if (a !== b) {
      //   this.$store.dispatch("GET_ORDERS", {
      //     filter: this.filter,
      //     client: null,
      //     page: 1,
      //     size: 100,
      //     from: this.from,
      //     to: b,
      //   });
      // }
      localStorage.setItem("from", a);
    },
    to: function (a, b) {
      localStorage.setItem("to", a);
      if (a !== b) {
        this.$store.dispatch("GET_ORDERS", {
          filter: "All Orders",
          client: null,
          page: 1,
          size: 100,
          from: this.from,
          to: a,
        });
      }
    },
    // "$this.$store.getters.getOrders": function () {
    // },
    // eslint-disable-next-line no-unused-vars
    "$store.state.filter.type": function (newFilter, oldFilter) {
      this.search = "";
    },
    // eslint-disable-next-line no-unused-vars
    "$store.state.tags": function (newFilter, oldFilter) {
      this.tags = newFilter;
      window.scrollTo(0, 0);
    },
    // "$store.state.orders": function (a) {
    //   this.orders = a;
    // },
  },
  methods: {
    exportOrders() {
      var yesterday = new Date(Date.now() - 86400000); // that is: 24 * 60 * 60 * 1000
      console.log(this.from);
      console.log(this.to);
      ikebana
        .exportToExcel(
          this.from === "" ? moment(yesterday).format("MM-DD-YYYY") : this.from,
          this.to === "" ? moment(Date.now()).format("MM-DD-YYYY") : this.to
        )
        .then((response) => {
          console.log(response);
          var blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          var fileLink = document.createElement("a");

          fileLink.href = URL.createObjectURL(blob);
          fileLink.setAttribute("download", "Orders");
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    setListStyle() {
      this.$store.dispatch("SET_LIST_STYLE");
    },
    removeClientFilter() {
      this.filterByClient = null;
    },
    startTagging() {
      this.taggable = true;
    },
    endTagging() {
      this.taggable = false;
    },
    saveTags() {
      this.$store.commit("ADD_FILTER_TAGS", this.new_tag);
      this.new_tag = null;
      this.endTagging();
    },
    addNewOrder() {
      this.$refs.sidebar.open();
    },
    hide() {
      this.$refs.sidebar.close();
    },
    showFilter() {
      this.toggleFilter = !this.toggleFilter;
    },
    // filter(type, value) {
    //   this.$store.commit("FILTER", {
    //     type: type,
    //     value: value === "All Orders" ? "All Orders" : value.displayName,
    //   });
    //   if (this.$route.name !== "Orders") {
    //     this.$router.go(-1);
    //   }
    //   this.list = this.$store.getters.getOrders;
    // },
  },
  created: function () {},
};
</script>
<style lang="css">
.client-tag {
  background: #ffa500;
  color: black;
}
.client-filter input {
  height: auto !important;
}
.search-box {
  width: auto !important;
}
.search {
  display: flex;
  flex-wrap: wrap !important;
}

.search input {
  height: 50px !important;
  margin-left: 10px;
}
.loading {
  background: white;
  height: 79vh;
}
.advance-filter {
  margin-top: 10px;
}
.cats-container p {
  padding-left: 10px;
}

@media (max-width: 768px) {
  .cats-container p {
    font-size: 10px !important;
    text-align: start !important;
  }

  .cats-container {
    top: 10px !important;
  }
}

.cats-container {
  position: sticky !important;
  top: 0px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
  padding-left: 5px;
  overflow: auto;
  background: white;
}

.filter {
  padding: 5px;
  position: relative;
  border-radius: 12px;
  width: 100%;
  height: 60px;
  text-align: center;
  background: #ffffff;
  padding-top: 15px;
  cursor: pointer;
}

.filter p {
  color: #ad1f23;
}

.title {
  width: 50px;
  font-size: 12px;
  text-align: start;
}
.value {
  font-size: 25px !important;
  font-weight: bold;
}

.selected {
  background: #ad1f23 !important;
  -webkit-transition: background 200ms ease-in;
  -ms-transition: background 200ms ease-in;
  transition: background 200ms ease-in;
}

.selected .title {
  color: white !important;
}

.selected .value {
  color: white !important;
}

.remove {
  background: none;
}
ul {
  list-style: none;
}
ul li {
  border-radius: 8px;
  display: inline;
  margin-left: 10px;
}
.empty {
  width: 100%;
  background: #ffffff;
  border-radius: 15px;
  position: relative;
  height: 80vh;
}

.empty img {
  background-size: cover;
  background-position: center;
}

.empty h6 {
  vertical-align: middle;
  height: 200px;
  line-height: 200px;
  text-align: center;
}

.new-order button {
  border-radius: 12px;
  width: 70px;
  height: 60px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  font-size: 35px;
  background: #ffffff;
  margin-left: 5px;
  cursor: pointer;
  color: #ad1f23;
}
.order-header p {
  padding-left: 50px;
}

.order-list p {
  width: 100%;
  font-size: 12px;
  text-align: center;
}
</style>
