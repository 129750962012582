<template>
  <div class="attachment" :style="'background: url(' + data.path + ')'">
    <div @click.stop="deleteAttachment" class="btn remove">
      <span>x</span>
    </div>
  </div>
</template>
<script>
import ikebana from "../services/ikebana";
export default {
  props: {
    data: Object,
    orderId: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    deleteAttachment() {
      var confirm = window.confirm(
        "Are you sure you want to remove this attachment?"
      );
      if (!confirm) {
        return;
      }
      ikebana.deleteImage(this.orderId, this.data.id).then(
        (resp) => {
          this.$notify({
            duration: 10000,
            group: "warning",
            title: "Attachment remove",
            text: resp.data.mesage,
          });

          this.$emit("remove", this.index);
        },
        (err) => {
          this.$notify({
            duration: 10000,
            group: "error",
            title: "Attachment remove",
            text: err.response.data.message,
          });
        }
      );
    },
  },
  computed: {},
};
</script>
<style lang="css" scoped>
.attachment > .remove {
  background: rgba(44, 44, 44, 0.5);
  width: 40px !important;
  height: 40px !important;
  color: white;
  font-size: 18px;
  float: right;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.attachment {
  height: 180px !important;
  background-position: center;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  border-radius: 12px;
  margin-right: 10px;
  min-width: 280px;
}
</style>
