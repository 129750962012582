<template>
  <div class="row product" v-b-toggle.sidebar-border @click="showEdit">
    <div class="col-md">
      <div class="d-flex">
        <!-- <span>
          <img class="image" :src="item.image" />
        </span> -->
        {{ item.name }}
      </div>
    </div>
    <div class="col-md-1">{{ item.qty }}</div>
    <div class="col-md-1">{{ item.unit }}</div>
    <div class="col-md-1">{{ item.size }}</div>
    <div class="col-md">{{ item.description }}</div>
  </div>
</template>
<script>
export default {
  props: {
    item: Object,
    first: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showEdit() {
      this.$store.commit("SET_EDIT_ITEM", this.item);
    },
  },
  components: {},
};
</script>
<style lang="css" scoped>
.product {
  background: white;
  padding: 10px;
  vertical-align: middle;
  margin-bottom: 10px;
  text-align: start;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 10px;
}

.image {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 15px;
}
</style>
