<template>
  <div>
    <div class="order-list">
      <transition name="slide-fade" mode="in-out">
        <div>
          <table v-show="this.list.length > 0" class="table table-responsive">
            <tbody>
              <Order
                v-for="order in list"
                :key="order.id"
                :order="order"
              ></Order>
            </tbody>
          </table>
        </div>
      </transition>
      <transition name="slide-fade" mode="in-out">
        <div v-show="this.list.length === 0" class="empty">
          <div class="empty-order"></div>
          <h6>Oh no! You don't have any orders!</h6>
          <img src="../assets/empty_order.png" alt="" />
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import Order from "./Order.vue";
export default {
  props: {
    list: Array,
  },
  components: {
    Order,
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted: function () {},
};
</script>
<style lang="css" scoped>
.empty {
  margin-top: 12px;
  z-index: -1;
  overflow-y: hidden !important ;
  height: 100vh;
}
.table {
  border-spacing: 0px 12px;
  padding: 0px !important;
  background: transparent;
}
.table-header tr {
  font-size: 12px;
}

tbody {
  margin-top: 50px;
}
.fixed {
  position: fixed;
  top: 180px;
}

th {
  font-weight: normal;
  font-size: 12px;
  border: none;
}

.remove {
  background: none;
}
ul {
  list-style: none;
}
ul li {
  border-radius: 8px;
  display: inline;
  margin-left: 10px;
}

.new-order button {
  border-radius: 12px;
  width: 70px;
  height: 60px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  font-size: 35px;
  background: #ffffff;
  margin-left: 5px;
  cursor: pointer;
  color: #9a0021;
}
.order-header p {
  padding-left: 50px;
}
.order-list {
  min-height: 100vh;
  width: 100% !important;
}
.order-list p {
  width: 100%;
  font-size: 12px;
  text-align: center;
}
</style>
