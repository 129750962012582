<template>
  <div v-if="!$store.state.loading.users">
    <!-- <TopNav></TopNav> -->
    <div>
      <Sidebar
        :title="this.$store.state.edit.user !== null ? 'Edit User' : 'Add User'"
        ref="sidebar"
      >
        <EditUser
          @hide="hide"
          :user="this.$store.state.edit.user"
          v-if="this.$store.state.edit.user !== null"
        />
        <AddUser @hide="hide" v-else />
      </Sidebar>
      <UserList :list="$store.state.users"></UserList>
    </div>
    <Add text="user" :action="addUser" icon="plus" />
  </div>
  <b-spinner v-else class="spinner"></b-spinner>
</template>
<script>
// import TopNav from "../../components/TopNav.vue";
import AddUser from "./AddUser.vue";
import EditUser from "./EditUser.vue";
import UserList from "../../components/UserList.vue";
import Add from "../../components/Add.vue";
import Sidebar from "../../components/Sidebar.vue";
export default {
  components: {
    UserList,
    Add,
    Sidebar,
    EditUser,
    AddUser,
    // TopNav,
  },
  methods: {
    addUser() {
      this.$refs.sidebar.open();
    },
    hide() {
      this.$refs.sidebar.close();
    },
  },
  mounted: function () {
    this.$store.dispatch("GET_USERS");
  },
};
</script>
<style scoped>
.users-list p {
  padding-left: 50px;
}

.controls input,
button {
  margin-left: 15px;
  min-width: 120px;
}
.users p {
  font-size: 12px;
  text-align: center;
}
input {
  width: 100%;
  padding-left: 15px;
}
</style>
