<template>
  <transition name="slide-fade" mode="out-in">
    <button name="submit" @click="action" class="add-new-action" type="submit">
      <span>
        <b-icon scale="3" :icon="icon"></b-icon>
      </span>
    </button>
  </transition>
</template>
<script>
export default {
  props: {
    text: String,
    icon: String,
    action: {
      type: Function,
      default: function () {},
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="css">
.add-new-action {
  position: fixed;
  background: #ad1f23;
  bottom: 20px;
  right: 20px;
  height: 50px !important;
  width: 50px !important;
  min-height: 50px !important;
  min-width: 50px !important;
  color: rgb(255, 255, 255);
  border-radius: 25px !important;
  z-index: 999;
}
</style>
