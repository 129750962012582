<template>
  <div v-if="!$store.state.loading.items" class="items">
    <Sidebar ref="sidebar">
      <EditItem @hide="hide" v-if="this.$store.state.edit.item" />
      <AddItem @hide="hide" v-else />
    </Sidebar>
    <ItemList :list="$store.state.items"></ItemList>
    <Add :action="addItem" icon="plus" />
  </div>
  <b-spinner v-else class="spinner"></b-spinner>
</template>
<script>
import AddItem from "./AddItem.vue";
import Add from "../../components/Add.vue";
import EditItem from "./EditItem.vue";
import Sidebar from "../../components/Sidebar.vue";
import ItemList from "../../components/ItemList.vue";
export default {
  components: {
    ItemList,
    Sidebar,
    EditItem,
    Add,
    AddItem,
  },
  methods: {
    addItem() {
      this.$refs.sidebar.open();
    },
    hide() {
      this.$refs.sidebar.close();
    },
  },
  mounted: function () {
    this.$store.dispatch("GET_ITEMS");
  },
};
</script>
<style scoped>
.items-list p {
  padding-left: 50px;
}

.items p {
  width: 100%;
  font-size: 12px;
  text-align: center;
}
.controls input,
input {
  width: 100%;
  padding-left: 15px;
}
</style>
