<template>
  <div class="edit-item">
    <form v-if="item" class="edit-item-form" @submit.prevent="addNewItem">
      <div>
        <ImageUploader :image="item.image" ref="image" />
        <input
          type="text"
          class="form-control"
          placeholder="Item Name"
          required
          v-model="item.name"
        />
        <multiselect
          style="margin-bottom: 10px"
          :options="['Small', 'Medium', 'Large']"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="true"
          :preserve-search="false"
          placeholder="Search Items"
          :taggable="false"
          v-model="item.size"
          selectLabel=""
          :preselect-first="true"
        />
        <div class="d-flex">
          <input
            required
            type="text"
            style="margin-right: 10px"
            v-model="item.qty"
            class="form-control"
            placeholder="Quantity"
          />
          <input
            type="text"
            class="form-control"
            v-model="item.unit"
            required
            placeholder="Unit"
          />
        </div>
        <textarea
          style="margin-bottom: 10px"
          class="form-control"
          name="desc"
          cols="30"
          placeholder="Enter item description"
          v-model="item.description"
          rows="3"
          sz
        ></textarea>
      </div>
      <div class="d-flex action-buttons">
        <button
          style="margin-right: 5px"
          :disabled="loading"
          class="btn primary"
          @click="editItem"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span v-show="!loading">Save</span>
        </button>
        <button
          type="button"
          style="margin-right: 5px"
          class="btn destructive"
          @click="onCancel"
        >
          <span v-show="!loading">Cancel</span>
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import ImageUploader from "../../components/ImageUploader.vue";
import ikebana from "../../services/ikebana";
import Multiselect from "vue-multiselect";

export default {
  components: {
    ImageUploader,
    Multiselect,
  },
  data() {
    return {
      loading: false,
      item: null,
    };
  },
  methods: {
    editItem() {
      this.loading = true;
      let formData = new FormData();
      formData.append("image", this.$refs.image.imageFile);
      formData.append("name", this.item.name);
      formData.append("unit", this.item.unit);
      formData.append("qty", this.item.qty);
      formData.append("description", this.item.description);
      formData.append("size", this.item.size);
      this.loading = true;
      ikebana.editItem(this.item.id, formData).then((response) => {
        if (response.data) {
          this.loading = false;
          this.$store.commit("EDIT_ITEM", response.data);
          this.$emit("hide");
        }
      });
    },
    onCancel() {
      this.$emit("hide");
    },
  },
  mounted: function () {
    this.item = this.$store.state.edit.item;
  },
};
</script>
<style lang="css" scoped>
textarea {
  border-radius: 12px !important;
  padding: 10px;
  resize: none;
  width: 100%;
  resize: none;
}
</style>
