import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Notifications from "vue-notification";
import moment from "moment";
import VueSignaturePad from "vue-signature-pad";
Vue.component("v-select", vSelect);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(PerfectScrollbar);
Vue.use(Notifications);
Vue.use(VueSignaturePad);

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD-MMM-YYYY hh:mm");
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
