<template>
  <div class="container">
    <div class="delivery-note">
      <div class="d-flex justify-content-between header section">
        <div style="margin-top: 25px" class="col-md-4 logo"></div>
        <div class="col-md address">
          <h6>Ikebana Pvt Ltd</h6>
          <p>
            H. Elliot Villa, Guleynooran Magu <br />
            Male' City, Maldives <br />
            sales@ikebana.mv <br />
            Phone: +960 3006002 <br />
            Mobile: +960 7904909
          </p>
        </div>
      </div>
      <div class="row section dn-info">
        <h3
          style="color: black !important; font-weight: normal"
          class="text-center"
        >
          Delivery Note
        </h3>
        <div class="dn-header">
          <p class="text-start">
            <strong> Date:</strong> {{ Date() | formatDate }} <br />
            <strong> Delivery Note No:</strong> {{ order.dnId }} <br />
            <strong> Client:</strong> {{ order.billTo }} <br />
            <strong> Purchase Order No:</strong> {{ order.po }} <br />
          </p>
        </div>
      </div>
      <div class="d-flex section items">
        <table class="table">
          <thead>
            <th class="text-start">Description</th>
            <th class="text-center" width="150">Unit</th>
            <th class="text-center" width="150">Quantity</th>
          </thead>
          <tbody>
            <tr v-for="(item, index) in order.items" :key="index">
              <td class="start">
                <div>
                  <img
                    :src="item.product.image"
                    style="border-radius: 25px"
                    width="30"
                    height="30"
                  />
                  {{ item.product.name }} - {{ item.productSize }}
                </div>
              </td>
              <td class="text-center">{{ item.product.unit }}</td>
              <td class="text-center end">
                <div>{{ item.qty }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-between">
        <div class="order-content package-facts">
          <div style="padding-top: 10px; padding-bottom: 10px">
            <p class="heading" style="text-align: start">Package Facts:</p>
            <p style="text-align: start">
              <span style="font-weight: bold">Number of Box:</span>
              {{ order.orderFact.numberOfBox }} <br />
              <span style="font-weight: bold">Weight:</span>
              {{ order.orderFact.weight }} <br />
              <span style="font-weight: bold">Received By:</span>
              {{ fact.signee }} <br />
              <span style="font-weight: bold">Contact:</span>
              {{ fact.contact }} <br />
              <span style="font-weight: bold">Date Delivered:</span>
              {{ Date() | formatDate }}
            </p>
          </div>
        </div>
        <div class="signature-view">
          <img :src="file" class="sign" />
        </div>
      </div>
      <div class="html2pdf__page-break"></div>
      <div class="d-flex justify-content-between" style="padding-top: 20px">
        <div class="col-md">
          <h6>Tracking History</h6>
          <div class="tracking-info">
            <div
              v-for="item in order.activities"
              :key="item.id"
              class="tracking-item bg d-flex justify-content-between"
            >
              <p>
                {{ item.name }}
              </p>
              <div
                style="padding: 0; margin: 0"
                class="d-flex justify-content-between"
              >
                <span style="text-align: start" class="auditable">
                  {{ item.end | formatDate }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md" style="padding-top: 20px">
          <div class="row">
            <h6>Notes</h6>
            <div class="additional-notes">
              <div
                v-for="(item, index) in order.additionalNote"
                :key="index"
                class="tracking-item bg d-flex justify-content-between"
              >
                <p>
                  {{ item.note }}
                </p>
                <div
                  style="padding: 0; margin: 0"
                  class="d-flex justify-content-between"
                >
                  <span style="text-align: start" class="auditable">
                    {{ item.createdAt | formatDate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row section photos">
        <h6>Photos</h6>
        <div class="attachments">
          <img
            class="attachment rounded"
            v-for="img in order.attachments"
            :key="img.path"
            :src="img.path"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import ikebana from "../../services/ikebana";
export default {
  props: {
    order: Object,
    fact: {
      numberOfBox: Number,
      weight: String,
      signee: String,
      contact: String,
      transportationMode: String,
    },
    images: Array,
    file: String,
  },
  mounted: function () {
    this.images.forEach((image) => {
      this.order.attachments.push({
        path: image,
      });
    });
  },
};
</script>
<style lang="css" scoped>
.sign {
  max-width: 300px !important;
  padding-right: 30px;
  margin-top: 10px;
}
.items {
  min-height: 50vh;
}
.tracking-item p {
  padding: 0px !important;
}
.bg {
  background: #f2f2f2;
}

.tracking-item {
  padding: 10px 10px 0px 10px;
  border-radius: 15px;
  margin-bottom: 5px;
}
.attachments {
  display: flex;
  flex-wrap: wrap !important;
}
.attachment {
  margin-right: 10px;
  margin-top: 10px;
  height: 150px;
  border-radius: 12px !important;
}
.section p {
  line-height: 1.2em;
}
.section {
  border-bottom: 1px solid #b6b6b6;
  padding-top: 10px;
  padding-bottom: 10px;
}
.address {
  text-align: end;
}
table {
  border-spacing: 0 5px;
  border-collapse: separate;
  height: 100%;
}
.dn-header {
  table-layout: fixed;
}
.dn-header tr {
  background: transparent !important;
  height: 30px !important;
}
.dn-header tr td {
  background: transparent !important;
  height: 30px !important;
}
td {
  padding: 10px;
}
tr {
  background: #f8f8f8;
  line-height: 30px;
  min-height: 30px;
  height: 30px;
}
tr:hover {
  background: #f8f8f8;
}
.start {
  padding: 10px;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.end {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.logo {
  background: url("/logo.png") no-repeat;
  background-size: contain;
  min-height: 100px;
  min-width: 100px;
  height: 100px;
  width: 100px;
}

.delivery-note {
  padding: 35px;
  padding-top: 15px;
  background: white;
}
</style>
