import Vue from "vue";
import Vuex from "vuex";
import Ikebana from "../services/ikebana";
import auth from "./modules/auth";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    listStyle: null,
    current_order: null,
    new_order: {
      logo: null,
      client: null,
      date: null,
      invoiceId: "",
      customerNote: null,
      status: null,
      due: null,
      items: [],
      reference: [],
    },
    new_user: {
      firstName: null,
      lastName: null,
      phoneNumber: null,
      email: null,
      team: null,
    },
    orders: [],
    items: [
      {
        id: null,
        image: null,
        name: null,
        unit: null,
        supplier: null,
        qty: null,
        price: null,
      },
    ],
    users: [],
    clients: [],
    teams: [],
    tags: [],
    filter: {
      type: "All Orders",
      client: null,
      start: null,
      end: null,
    },
    edit: {
      item: null,
      user: null,
      client: null,
    },
    loading: {
      orders: false,
      users: false,
      items: false,
      clients: false,
    },
    notifications: [],
  },
  getters: {
    getListStyle: (state) => {
      return state.listStyle;
    },
    stageExist: (state) => (currentStage) => {
      var fi = state.stages.findIndex((stage) => stage === currentStage);
      return fi == -1;
    },
    getTags(state) {
      return state.tags;
    },
    getOrders(state) {
      if (state.filter.type === null) {
        state.filter.type = "All Orders";
      }
      var orders =
        state.filter.type === "All Orders"
          ? state.orders
          : state.orders.filter(
              (order) => order.team.displayName === state.filter.type
            );

      return orders;
    },
    getOrderById: (state) => (id) => {
      var order = state.orders.find((order) => order.id === id);
      return order;
    },
    getCount: (state) => (filter) => {
      if (filter === "All Orders") {
        return state.orders.length;
      }

      return state.orders.filter((order) => order.team.displayName === filter)
        .length;
    },
  },
  mutations: {
    SET_LIST_STYLE(state) {
      if (state.listStyle === "extended") {
        state.listStyle = "minimal";
      } else {
        state.listStyle = "extended";
      }
    },
    SET_ORDER_LIST(state, payload) {
      state.orders = payload;
      state.loading.orders = false;
    },
    SET_CURRENT_ORDER(state, payload) {
      state.current_order = payload;
    },
    SET_EDIT_ITEM(state, payload) {
      state.edit.item = payload;
    },
    SET_EDIT_USER(state, payload) {
      state.edit.user = payload;
    },
    SET_EDIT_CLIENT(state, payload) {
      state.edit.client = payload;
    },
    RESET_EDIT(state) {
      state.edit.item = null;
      state.new_order = {
        logo: null,
        client: null,
        date: null,
        invoiceId: null,
        status: null,
        due: null,
        items: [],
      };
      state.edit.user = null;
      state.edit.client = null;
    },
    FILTER(state, filter) {
      if (filter.type === "type") {
        state.filter.type = filter.value;
      }
      if (filter.type === "client") {
        state.filter.client = filter.value;
      }

      if (filter.type === "start") {
        state.filter.start = filter.value;
      }

      if (filter.type === "end") {
        state.filter.end = filter.value;
      }
    },
    REMOVE_FILTER(state, filter) {
      state.filter = filter;
    },
    UPDATE_STATUS(state, ord) {
      var order = state.orders.find((order) => order.id === ord.id);
      order.status = ord.status;
    },
    UPDATE_ORDER(state, ord) {
      console.log("Find order by id : " + ord.id);
      const order = state.orders.find((order) => order.id === ord.id);

      console.log(order);
      if (!order) {
        console.log("Order not found");
        state.orders.unshift(ord);
        return;
      }
      order.team = ord.team ?? order.team;
      order.tags = ord.tags;
      order.activities = ord.activities ?? order.activities;
      order.additionalNote = ord.additionalNote ?? order.additionalNote;
      order.attachments = ord.attachments ?? order.attachments;
      order.billTo = ord.billTo ?? order.billTo;
      order.client = ord.client ?? order.client;
      order.correspondent = ord.correspondent ?? order.correspondent;
      order.dueDate = ord.dueDate ?? order.dueDate;
      order.modifiedAt = ord.modifiedAt ?? order.modifiedAt;
      order.users = ord.users ?? order.users;
    },
    GET_CLIENTS(state) {
      state.loading.clients = true;
      Ikebana.getClients().then(
        (response) => {
          state.clients = response.data;
          state.loading.clients = false;
        },
        () => {
          state.loading.clients = false;
        }
      );
    },
    GET_USERS(state) {
      state.loading.users = true;
      Ikebana.getUsers().then(
        (response) => {
          state.users = response.data;
          state.loading.users = false;
        },
        () => {
          state.loading.users = false;
        }
      );
    },
    GET_ITEMS(state) {
      state.loading.items = true;
      Ikebana.getItems().then(
        (response) => {
          state.items = response.data;
          state.loading.items = false;
        },
        () => {
          state.loading.items = false;
        }
      );
    },
    GET_ORDERS(state, payload) {
      if (state.loading.orders) {
        return;
      }
      state.loading.orders = true;
      state.orders = [];
      Ikebana.getOrders(payload).then(
        (response) => {
          state.orders = response.data;
          state.loading.orders = false;
        },
        () => {
          state.loading.orders = false;
        }
      );
    },
    GET_TEAMS(state) {
      state.loading.users = true;
      Ikebana.getTeams().then(
        (response) => {
          console.log(response.data);
          state.teams = response.data;
          state.loading.users = false;
        },
        () => {
          state.loading.users = false;
        }
      );
    },
    ADD_NEW_ORDER(state, payload) {
      state.orders.push(payload);
    },
    ADD_NEW_CLIENT(state, payload) {
      state.clients.push(payload);
    },
    ADD_NEW_CORRESPONDENT(state, payload) {
      state.clients.correspondent.push(payload);
    },
    ADD_NEW_ITEM(state, payload) {
      state.items.push(payload);
    },
    ADD_NEW_USER(state, payload) {
      state.users.push(payload);
    },
    DELETE_USER(state, payload) {
      state.users.splice(state.users.indexOf(payload), 1);
    },
    EDIT_ITEM(state, payload) {
      state.items.find((item) => item.id === payload.id) == payload;
    },
    ADD_FILTER_TAGS(state, tag) {
      var _tag = state.tags.find((x) => x === tag);
      if (!_tag) {
        state.tags.push(tag);
      }
    },
    REMOVE_FILTER_TAGS(state, tag) {
      var _tag = state.tags.find((x) => x === tag);
      if (_tag) {
        state.tags.splice(state.tags.indexOf(_tag), 1);
      }
    },
  },
  actions: {
    SET_LIST_STYLE({ commit }) {
      commit("SET_LIST_STYLE");
    },
    SET_ORDER_LIST({ commit }, payload) {
      commit("SET_ORDER_LIST", payload);
    },
    UPDATE_STATUS({ commit }, payload) {
      commit("UPDATE_STATUS", payload);
    },
    UPDATE_ORDER({ commit }, payload) {
      commit("UPDATE_ORDER", payload);
    },
    SET_CURRENT_ORDER({ commit }, payload) {
      commit("SET_CURRENT_ORDER", payload);
    },
    ADD_NEW_USER({ commit }, payload) {
      commit("ADD_NEW_USER", payload);
    },
    LOAD_DATA(context) {
      context.commit("GET_TEAMS");
      context.commit("GET_CLIENTS");
      context.commit("GET_ITEMS");
      context.commit("GET_USERS");
    },
    GET_ITEMS(context) {
      context.commit("GET_ITEMS");
    },
    RESET_EDIT(context) {
      context.commit("RESET_EDIT");
    },
    GET_CLIENTS(context) {
      context.commit("GET_CLIENTS");
    },
    GET_USERS(context) {
      context.commit("GET_USERS");
    },
    GET_ORDERS({ commit }, payload) {
      commit("GET_ORDERS", payload);
    },
    ADD_FILTER_TAGS({ commit }, tag) {
      commit("ADD_FILTER_TAGS", tag);
    },
    REMOVE_FILTER_TAGS({ commit }, tag) {
      commit("REMOVE_FILTER_TAGS", tag);
    },
  },
  modules: {
    auth,
  },
  plugins: [
    createPersistedState({
      key: "Ikebana Store",
      paths: ["auth", "teams", "clients", "items", "users", "listStyle"],
    }),
  ],
});
