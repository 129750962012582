<template>
  <div class="container-fluid">
    <div class="row">
      <Navigation />
      <div class="col-md">
        <Install />
        <div class="main-content">
          <transition name="slide-fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </div>
      </div>
    </div>
    <!-- <p style="position: absolute; bottom: 0; right: 44%; margin-top: 20px">
      Copyright © 2021 Ads Maldives. All rights reserved.
    </p> -->
    <notifications
      :position="isMobile ? 'bottom center' : 'bottom right'"
      class="error"
      group="error"
    />
    <notifications
      :position="isMobile ? 'bottom center' : 'bottom right'"
      class="warning"
      group="warning"
    />
    <notifications
      :position="isMobile ? 'bottom center' : 'bottom right'"
      class="success"
      group="success"
    />
  </div>
</template>
<script>
import Navigation from "../../components/Navigation.vue";
import Install from "../../components/Install.vue";
export default {
  components: {
    Install,
    Navigation,
  },
  data() {
    return {
      pageId: 1,
      sidebar: [
        {
          id: 1,
          to: "/order/list",
          name: "Orders",
          icon: "calendar2-check",
          active: false,
        },
        {
          id: 2,
          to: "/users",
          name: "Users",
          icon: "calendar2-check",
          active: false,
        },
        {
          id: 3,
          to: "/items",
          name: "Items",
          icon: "calendar2-check",
          active: false,
        },
        {
          id: 4,
          to: "/clients",
          name: "Clients",
          icon: "calendar2-check",
          active: false,
        },
      ],
    };
  },

  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    navigateTo(page) {
      this.pageId = page.id;
      this.$router.push(page.to);
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/auth/login");
    },
    collapseSidebar() {
      setTimeout(() => (this.collapsed = true), 2000);
    },
    getToken() {},
  },
  mounted: function () {},
  watch: {
    $route() {
      if (this.$refs.scroll) {
        this.$refs.scroll.$el.scrollTop = 0;
      }
    },
    "$store.state.filter.type": function () {
      if (this.$refs.scroll) {
        this.$refs.scroll.$el.scrollTop = 0;
      }
    },
  },
  created: function () {},
};
</script>
<style lang="css">
.sidebar {
  height: 100vh;
  position: fixed;
}

.main-content {
  padding-top: 10px;
}
.body {
  min-height: 60vh;
  overflow: hidden !important;
}

.vue-notification {
  border-radius: 12px;
  border: none !important;
}
.success .vue-notification {
  background: #1aa100 !important;
}
.warning .vue-notification {
  background: #dbb300 !important;
  color: black;
}
.error .vue-notification {
  background: #ad1f23 !important;
}
</style>
