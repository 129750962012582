<template>
  <div class="item-list">
    <div class="item-list-top">
      <div class="d-flex justify-content-between">
        <div @click="goback()" class="d-block d-sm-none">
          <button class="btn">
            <b-icon
              scale="1"
              style="color: #ad1f23"
              icon="chevron-left"
            ></b-icon>
          </button>
        </div>
        <div class="route-title">
          <h5>{{ this.$route.name }}</h5>
        </div>
        <input type="text" placeholder="Search Items" v-model="search" />
      </div>
    </div>
    <div class="items" v-if="getItems.length > 0">
      <Item
        v-for="(item, index) in getItems"
        :key="item.id"
        :first="index === 0"
        :item="item"
      ></Item>
    </div>
    <div class="empty" v-else>
      <div class="empty-order"></div>
      <h6 v-if="search !== ''">
        Items with search string '{{ search }}' not found
      </h6>
      <img src="../assets/empty_order.png" alt="" />
    </div>
  </div>
</template>
<script>
import Item from "./Item.vue";
export default {
  props: {
    list: Array,
  },
  data() {
    return {
      search: "",
      itemList: this.list,
    };
  },
  methods: {
    goback() {
      this.$router.push({ path: "/dashboard" });
    },
  },
  computed: {
    getItems() {
      if (this.search === "") {
        return this.itemList;
      }
      return this.itemList.filter((item) => {
        return item.name.toLowerCase().match(this.search.toLowerCase());
      });
    },
  },
  components: {
    Item,
  },
};
</script>
<style lang="css" scoped>
.empty {
  margin-top: 12px;
  z-index: -1;
  overflow-y: hidden !important ;
  height: 100vh;
}
.item-list-top {
  width: 100%;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 0px;
  vertical-align: middle !important;
  align-content: center !important;
  background: white;
  border-radius: 12px;
  padding: 10px;
}

.route-title {
  vertical-align: middle !important;
}
.items {
  margin-top: 10px;
}
</style>
