<template>
  <div class="d-flex" style="margin-bottom: 5px">
    <div style="width: 100%">
      <img :src="item.product.image" />
      <input
        class="item-name"
        type="text"
        style="padding-left: 70px"
        disabled
        :value="item.product.name + ' - ' + item.size"
      />
    </div>
    <div>
      <p class="unit">{{ item.product.unit }}</p>
    </div>
    <div>
      <input type="number" v-model="item.qty" />
    </div>
    <div>
      <button @click="remove" type="button" class="remove">
        <b-icon scale="1.5" icon="x"></b-icon>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: Object,
  },
  methods: {
    qtyMinus() {
      if (this.item.qty === 1) {
        this.item == null;
      } else {
        this.item.qty--;
      }
    },
    qtyPlus() {
      this.item.qty++;
    },
    remove() {
      this.$emit("remove", this.item);
    },
  },
};
</script>
<style lang="css" scoped>
.item-name {
  word-break: break-all !important;
  word-wrap: break-word !important;
  font-size: 12px;
}

.remove {
  background: white;
  color: red;
  height: 50px;
  width: 50px;
  border: 1px solid #e8e8e8;
}
.unit {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 100px;
  padding-top: 15px;
}
img {
  width: 40px;
  height: 40px;
  position: absolute;
  margin: 5px;
  border-radius: 25px;
  border: 1px solid #e8e8e8;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
div {
  padding-left: 5px;
  padding-right: 5px;
}
input {
  margin: 0px;
  width: 100% !important;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
