import firebase from "firebase/app";
import "firebase/messaging";

const config = {
  apiKey: "AIzaSyBDXCqW8jfX7wZVXcgyiYmNnw9VSY7bxdM",
  authDomain: "ikebana-4e5db.firebaseapp.com",
  projectId: "ikebana-4e5db",
  storageBucket: "ikebana-4e5db.appspot.com",
  messagingSenderId: "911510630347",
  appId: "1:911510630347:web:06d0c2c1238d2fb0bc904e",
  measurementId: "G-NTD77P0BZK",
};

var messaging = null;
if (!firebase.messaging.isSupported()) {
  console.log("Push notification not supported");
} else {
  firebase.initializeApp(config);
  messaging = firebase.messaging();
}

export default messaging;
