<template>
  <div class="package-info">
    <form class="package-info-form" @submit.prevent>
      <div>
        <div class="d-flex">
          <input
            type="text"
            class="form-control"
            v-model="order.transportMethod"
            required
            style="margin-right: 10px"
            placeholder="Transport mode"
          />
          <input
            type="text"
            v-model="fact.contact"
            class="form-control"
            required
            placeholder="Signee contact number"
          />
        </div>
        <input
          type="text"
          class="form-control"
          v-model="fact.signee"
          required
          style="margin-right: 10px"
          placeholder="Signee name"
        />
      </div>

      <p class="caption">
        Please fill the form accuratly. This information will be passed to the
        client.
      </p>
      <div class="add-note">
        <h6 class="text-start">Note:</h6>
        <textarea
          name="remarks"
          id=""
          cols="30"
          placeholder="Add note"
          v-model="note"
          required
          style="
            width: 100%;
            resize: none;
            border: 1px solid #e9e9e9;
            border-radius: 12px;
          "
          rows="3"
        ></textarea>
        <p class="caption">This note will be visible to the client.</p>
      </div>
      <div class="attach-image">
        <div class="d-flex justify-content-between">
          <div>
            <h6 class="text-start">Add Reference Image(s)</h6>
          </div>
          <div>
            <input
              style="color: white; height: 40px !important"
              type="file"
              @change="onFileChange"
              multiple
              accept="image/png, image/gif,
            image/jpeg"
              class="file-input"
            />
          </div>
        </div>
        <div class="attachments">
          <img
            class="attachment"
            v-for="(image, index) in images"
            :key="index"
            :src="image"
          />
          <p class="caption">
            These attachments will be visible to the client.
          </p>
        </div>
      </div>
      <div class="signature">
        <div class="d-flex justify-content-between">
          <p style="text-align: start" class="heading">Signature</p>
          <button type="button" @click="undo">
            <span
              >Retry
              <b-icon icon="arrow-repeat"></b-icon>
            </span>
          </button>
        </div>
        <VueSignaturePad
          class="signature-pad"
          :options="{ onBegin, onEnd }"
          height="30vh"
          width="100%"
          ref="signaturePad"
        />
      </div>
      <div class="d-flex action-buttons">
        <button
          type="submit"
          style="margin-right: 5px; background: #1aa100 !important"
          :disabled="loading"
          class="btn primary"
          @click="save"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span v-show="!loading">Mark as Delivered</span>
        </button>
        <input
          type="button"
          style="margin-left: 5px"
          class="btn destructive"
          value="Cancel"
          @click="onCancel"
        />
      </div>
    </form>
    <PDFGenerator
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :filename="order.dnId"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      ref="html2Pdf"
      pdf-content-width="800px"
      @hasDownloaded="hasDownloaded($event)"
    >
      <section
        slot="pdf-content"
        :class="generatingPDF ? 'inline-block' : 'd-none'"
      >
        <DeliveryNote :order="order" :fact="fact" :file="file"></DeliveryNote>
      </section>
    </PDFGenerator>
  </div>
</template>
<script>
import DeliveryNote from "./DeliveryNote.vue";
import ikebana from "../../services/ikebana";
import PDFGenerator from "../../components/PDFGenerator.vue";
//pdfFile
export default {
  components: {
    DeliveryNote,
    PDFGenerator,
  },
  props: {
    order: Object,
  },
  data() {
    return {
      generatingPDF: false,
      fact: {
        numberOfBox: null,
        weight: null,
        signee: null,
        contact: null,
        transportationMode: null,
        path: null,
      },
      images: [],
      note: null,
      files: [],
      file: null,
      loading: false,
      signatureFile: null,
    };
  },
  methods: {
    async hasDownloaded(blob) {
      let formData = new FormData();
      this.files.forEach((element) => {
        formData.append("Attachments", element);
      });
      formData.append("file", this.signatureFile);
      formData.append("deliveryNote", blob);
      formData.append("signee", this.fact.signee);
      formData.append("weight", this.fact.weight);
      formData.append("note", this.note);
      formData.append("contact", this.fact.contact);
      formData.append("transportationMode", this.order.transportMethod);
      ikebana.markDelivered(this.order.id, formData).then(
        (response) => {
          this.loading = false;
          this.$notify({
            duration: 5000,
            group: "success",
            title: "Important message",
            text: "Order marked as delivered",
          });
          this.$emit("endAction", response);
        },
        (error) => {
          this.loading = false;
          this.$notify({
            duration: 5000,
            group: "error",
            title: "Important message",
            text: error.response.data.Message,
          });
        }
      );
    },

    async undo() {
      this.$refs.signaturePad.undoSignature();
    },
    onCancel() {
      this.$emit("hide");
    },
    onFileChange(e) {
      e.target.files.forEach((file) => {
        var image = URL.createObjectURL(file);
        this.images.push(image);
        this.files.push(file);
        this.order.attachments.push({
          path: image,
        });
      });
    },
    async save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$notify({
          duration: 5000,
          group: "error",
          title: "Important message",
          text: "Please get the signature",
        });
        return;
      }
      this.loading = true;
      this.order.activities.push({
        id: -1,
        name: "Package Delivered",
        end: Date(),
      });

      if (this.note) {
        this.order.additionalNote.push({
          note: this.note,
          createdAt: Date(),
        });
      }

      // helper function: generate a new file from base64 String
      const dataURLtoFile = (dataurl) => {
        const arr = dataurl.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n) {
          u8arr[n - 1] = bstr.charCodeAt(n - 1);
          n -= 1; // to make eslint happy
        }
        return new File([u8arr], "signature.png", { type: mime });
      };
      // generate file from base64 string
      this.signatureFile = dataURLtoFile(data);
      // put file into form data
      this.file = URL.createObjectURL(this.signatureFile);
      this.generatingPDF = true;
      await this.$refs.html2Pdf.generatePdf();
      this.generatingPDF = false;
    },
    onBegin() {
      this.$refs.signaturePad.resizeCanvas();
    },
    onEnd() {},
  },
};
</script>
<style lang="css" scoped>
.caption {
  font-size: 12px;
  text-align: start;
}
.signature-pad {
  border: 2px dashed #ccc;
  border-radius: 12px;
  margin-bottom: 10px;
}

.package-info {
  padding-top: 10px;
}
.attachments {
  width: 100% !important;
  white-space: nowrap;

  overflow-x: auto !important;
  overflow-y: hidden !important;
}
.attachment {
  height: 20vh;
  border-radius: 12px;
  margin-right: 10px;
}
.file-input {
  width: 110px !important;
  height: 35px !important;
  background: #008cf6 !important;
  color: white;
  background-size: cover;
}

.file-input::-webkit-file-upload-button {
  visibility: hidden;
  width: 100%;
}
.file-input::before {
  content: "Add Image";
  -webkit-user-select: none;
  cursor: pointer;
  font-size: 12pt;
  z-index: 9999;
}
.file-input:hover::before {
  border-color: black;
}
</style>
