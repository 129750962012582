<template>
  <div class="add-client">
    <form class="add-client-form" @submit.prevent="addNewItem">
      <div>
        <ImageUploader :image="client.logo" ref="image" />
        <input
          type="text"
          class="form-control"
          placeholder="Client Name"
          required
          v-model="client.name"
        />
        <div class="d-flex">
          <input
            type="text"
            class="form-control"
            style="margin-right: 10px"
            required
            placeholder="Correspondent Name"
            v-model="client.correspondent"
          />
          <input
            type="text"
            class="form-control"
            required
            placeholder="Department"
            v-model="client.department"
          />
        </div>
        <div class="d-flex">
          <input
            required
            type="text"
            style="margin-right: 10px"
            v-model="client.designation"
            class="form-control"
            placeholder="Designation"
          />
          <input
            type="text"
            class="form-control"
            v-model="client.email"
            required
            placeholder="Email"
          />
        </div>
        <input
          type="text"
          class="form-control"
          v-model="client.phoneNumber"
          placeholder="Phone Number"
        />
        <div class="d-flex">
          <button
            style="margin-right: 5px"
            :disabled="loading"
            class="btn primary"
            @click="addNewClient"
          >
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-show="!loading">Add</span>
          </button>
          <button
            type="button"
            style="margin-right: 5px"
            class="btn destructive"
            @click="onCancel"
          >
            <span v-show="!loading">Cancel</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import ImageUploader from "../../components/ImageUploader.vue";
import ikebana from "../../services/ikebana";

export default {
  components: {
    ImageUploader,
  },
  data() {
    return {
      loading: false,
      client: {
        id: null,
        logo: null,
        name: null,
        correspondent: null,
        department: null,
        designation: null,
        email: null,
        phoneNumber: null,
      },
    };
  },
  methods: {
    addNewClient() {
      this.loading = true;
      let formData = new FormData();
      formData.append("logo", this.$refs.image.imageFile);
      formData.append("name", this.client.name);
      formData.append("correspondent", this.client.correspondent);
      formData.append("department", this.client.department);
      formData.append("designation", this.client.designation);
      formData.append("email", this.client.email);
      formData.append("phoneNumber", this.client.phoneNumber);
      this.loading = true;
      ikebana.addNewClient(formData).then(
        (response) => {
          this.loading = false;
          this.$store.commit("ADD_NEW_CLIENT", response.data);
          this.$emit("hide");
        },
        (error) => {
          this.loading = false;
          this.$notify({
            duration: 5000,
            group: "error",
            title: "Important message",
            text: error.response.data.Message,
          });
        }
      );
    },
    onCancel() {
      this.$emit("hide");
    },
  },
};
</script>
<style lang="css"></style>
