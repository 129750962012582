<template>
  <div class="edit-order">
    <form @submit.prevent>
      <div>
        <!-- <ImageUploader /> -->
        <div class="d-flex">
          <label class="col-2" for="date">Date: </label>
          <input
            ref="date"
            required
            class="col"
            name="date"
            :value="order.dueDate.substring(0, 10)"
            @input="order.dueDate = new Date($event.target.value)"
            type="date"
            placeholder="Date"
          />
        </div>
        <div class="d-flex">
          <label class="col-2" for="text">Po Number: </label>
          <input
            required
            class="col"
            name="date"
            type="text"
            v-model="order.po"
            placeholder="Order number"
          />
        </div>
        <div class="my-2">
          <input
            required
            class="col"
            name="invoiceId"
            type="text"
            v-model="order.customerNote"
            placeholder="Edit Customer note"
          />
        </div>
        <transition name="slide-fade" mode="out-in">
          <div v-if="order.client === null" class="d-flex">
            <label class="col-2" for="text">Client:</label>
          </div>
          <div class="d-flex justify-content-between selected-client" v-else>
            <div class="d-flex">
              <img
                style="border-radius: 25px; border: 1px solid #e8e8e8"
                width="40"
                height="40"
                :src="order.client.logo"
              />
              <div
                style="padding-top: 10px; padding-left: 10px; color: #ccc"
                for="text"
              >
                {{ order.client.name }}
              </div>
            </div>
          </div>
        </transition>
        <multiselect
          v-if="correspondents"
          v-model="order.correspondents"
          placeholder="Add Correspondents"
          :options="correspondents"
          :searchable="true"
          :preselect-first="false"
          label="name"
          :close-on-select="false"
          :clear-on-select="true"
          track-by="name"
          selectLabel=""
          :multiple="true"
          :allow-empty="true"
          :taggable="true"
        >
        </multiselect>
        <div style="min-height: 10px"></div>
        <multiselect
          :options="$store.state.items"
          :multiple="true"
          :close-on-select="true"
          :clear-on-select="true"
          :preserve-search="false"
          placeholder="Search Items"
          :taggable="false"
          :custom-label="itemWithSize"
          track-by="name"
          @select="selectItem"
          selectLabel=""
          :preselect-first="false"
        />
        <label v-show="this.order.items.length > 0" style="width: 100%"
          >Items:</label
        >

        <div
          v-show="this.order.items.length > 0"
          class="order-item-list-container"
        >
          <table class="order-item-list">
            <!-- <div v-for="(item, index) in this.order.items" :key="index" class="">
            <NewItem :item="item" />
          </div> -->

            <tbody>
              <tr v-for="(item, index) in this.order.items" :key="index">
                <NewEditItem @remove="remove" :item="item" />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <button class="btn destructive delete-button">Delete Item</button> -->
      <div class="d-flex action-buttons">
        <button
          style="margin-right: 5px"
          :disabled="loading"
          class="btn primary"
          @click="updateOrder"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span v-show="!loading">Update</span>
        </button>
        <button type="button" class="btn destructive" @click="dontSave">
          Dont Save
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import NewEditItem from "../../components/NewEditItem.vue";
// import ImageUploader from "../../components/ImageUploader.vue";
import Multiselect from "vue-multiselect";
import Ikebana from "../../services/ikebana";
export default {
  props: {
    order: Object,
  },
  components: {
    // ImageUploader,
    NewEditItem,
    Multiselect,
  },

  data() {
    return {
      loading: false,
      itemName: "",
      client: null,
      correspondents: null,
      files: [],
      note: "",
    };
  },
  computed: {
    getDate() {
      const changedDate = this.order.dueDate.replace(
        /(..)\/(..)\/(....) (..):(..)/,
        "$3-$2-$1 $4:$5"
      );
      var date = new Date(changedDate);
      return date;
    },
  },
  methods: {
    remove(item) {
      console.log(item);
      var _item = this.order.items.find((i) => i.id === item.id);
      console.log(_item);

      this.order.items.splice(this.order.items.indexOf(_item), 1);
      console.log(this.order.items);
    },
    itemWithSize({ name, size }) {
      return name + " - " + size;
    },
    selectItem(item) {
      var _item = this.order.items.find((i) => i.id === item.id);
      if (_item) {
        _item.qty++;
      } else {
        this.order.items.push({
          // productId: item.productId,
          // orderId: this.order.id,
          // product: item,
          // productSize: item.size,
          // size: item.size,
          // qty: 1,
          orderId: this.order.id,
          productId: item.id,
          product: item,
          productSize: item.size,
          size: item.size,
          qty: 1,
        });
      }
    },
    selectClient(client) {
      this.client = client;
      this.order.client = client.id;
    },
    search_client() {
      if (this.$store.state.clients.length === 0) {
        this.$store.dispatch("GET_CLIENTS");
      }

      var final = this.$store.state.clients.filter((item) =>
        item.name.includes(this.client)
      );

      if (final.length === 1) {
        this.order.client = final[0].id;
      }
    },
    fileSelected(e) {
      e.target.files.forEach((element) => {
        this.files.push(element);
      });
    },

    updateOrder() {
      this.loading = true;
      Ikebana.updateOrder(this.order.id, this.order).then(
        () => {
          this.loading = false;
          this.$emit("hide");
        },
        (error) => {
          this.loading = false;
          this.$emit("hide");
          this.$notify({
            duration: 5000,
            group: "error",
            title: "Important message",
            text: error.response.data.Message,
          });
        }
      );
    },
    dontSave() {
      this.$emit("hide");
    },
    setClient() {},
    removeSelectedClient() {
      this.order.logo = null;
      this.order.client = null;
    },
  },
  mounted: function () {
    this.client = this.$store.state.clients.find(
      (c) => c.id === this.order.client.id
    );
    this.correspondents = this.client.correspondents;
  },
};
</script>
<style lang="css" scoped>
.order-item-list-container {
  border: 1px solid #e8e8e8;
  padding: 5px !important;
  border-radius: 10px !important;
}
.order-item-list {
  table-layout: fixed;
  width: 100%;
}
.order-item-list tr:hover {
  background: transparent;
}
.selected-client div {
  padding-top: 5px;
}
.selected-client {
  vertical-align: middle;
  border-radius: 10px;
  padding: 5px;
  border: 1px solid #e8e8e8;
  margin-bottom: 10px;
}

.input-w-suffix {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.input-group-addon {
  padding: 15px 20px;
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #ad1f23;
  vertical-align: middle;
  text-align: center;
  border: 1px solid #e8e8e8;
  width: 2%;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
}

input {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 0;
  display: table-cell;
  padding: 6px 12px;
  border: 1px solid #e8e8e8;
  margin: 0;
}

.suffix input {
  border-radius: 12px 0px 0px 12px;
}
.suffix .input-group-addon {
  border-left: 0;
  border-radius: 0px 12px 12px 0px;
}
.prefix input {
  border-radius: 0px 12px 12px 0px;
}
.prefix .input-group-addon {
  border-right: 0;
  border-radius: 12px 0px 0px 12px;
}
.presuffix input {
  border-radius: 0px;
}
.input-group-addon.prefix {
  border-radius: 12px 0px 0px 12px;
  border-right: 0;
}
.input-group-addon.suffix {
  border-radius: 0px 12px 12px 0px;
  border-left: 0;
}
.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}

label {
  padding-right: 10px !important;
  color: #ad1f23;
  text-align: start !important;
  width: 20%;
}
.delete-button {
  border: none !important;
  margin-top: -100px;
}

.edit-order {
  height: 100%;
  padding-top: 10px;
}
form {
  padding-left: 10px;
  height: 100% !important;
  padding-right: 10px;
}
@media (max-width: 767.98px) {
  .edit-order-form {
    padding: 10px !important;
  }
}
input {
  margin-bottom: 10px;
  height: 50px;
}
</style>
